import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Blog from '../../components/blog/layout-two';
import Pagination from '../../components/blog/pagination';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import { Col, Container, Row } from '../../components/ui/Wrapper';
import Sidebar from '../../containers/blog/sidebar';
import Footer from '../../containers/Layout/Footer';
import Header from '../../containers/Layout/Header';
import { BlogBox, BlogBoxWrapper, BlogListWrapper } from './blog-list.style';

const BlogList = ({ data, pageContext, location }) => {
  const blogs = data.allContentfulBlogPost.edges;

  const { humanPageNumber, numberOfPages, authorSlug, categorySlug } = pageContext;

  const getFilterSlug = () => {
    if (authorSlug) return `/autor/${authorSlug}`;
    if (categorySlug) return `/categoria/${categorySlug}`;
    return '';
  };

  const rootPath = `/blog${getFilterSlug()}`;

  return (
    <>
      <SEO title={`Blog: Página ${humanPageNumber}`} />
      <Header menuBasedOnCookies />
      <PageHeader pageContext={pageContext} location={location} title="Blog do Garra " />
      <main className="site-wrapper-reveal">
        <BlogListWrapper>
          <Container>
            <Row>
              <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
                <Sidebar />
              </Col>
              <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
                <BlogBoxWrapper>
                  {blogs.map(({ node: blog }) => (
                    <BlogBox key={blog.slug}>
                      <Blog content={blog} rootPath={rootPath} />
                    </BlogBox>
                  ))}
                </BlogBoxWrapper>
                <Pagination rootPage={rootPath} currentPage={humanPageNumber} numberOfPages={numberOfPages} />
              </Col>
            </Row>
          </Container>
        </BlogListWrapper>
      </main>
      <Footer />
    </>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!, $categorySlugRegex: String = "//", $authorSlugRegex: String = "//") {
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      skip: $skip
      limit: $limit
      filter: {
        availableOnGarra: { eq: true }
        author: { slug: { regex: $authorSlugRegex } }
        category: { elemMatch: { slug: { regex: $categorySlugRegex } } }
      }
    ) {
      edges {
        node {
          slug
          title
          createdAt(formatString: "LL", locale: "pt-BR")
          publishDate(formatString: "LL", locale: "pt-BR")
          content {
            childContentfulRichText {
              html
            }
          }
          author {
            name
            slug
          }
          category {
            slug
            title
          }
          coverImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;

BlogList.propTypes = {
  pageContext: PropTypes.shape({
    humanPageNumber: PropTypes.number,
    numberOfPages: PropTypes.number,
    authorSlug: PropTypes.string,
    categorySlug: PropTypes.string,
  }).isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogList;
