import PropTypes from 'prop-types';
import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import Img from 'gatsby-image';
import Truncate from 'react-truncate-html';
import Anchor from '../../ui/Anchor';
import Button from '../../ui/button';
import AuthorMeta from '../author-meta';
import BlogMeta from '../blog-meta';
import {
  BlogFooter,
  BlogHeader,
  BlogHeaderMeta,
  BlogInfo,
  BlogTitle,
  BlogWrapper,
  BlogFooterLeft,
  BlogThumb,
  BlogExcerpt,
} from './blog.style';
import { slugify } from '../../../utils/utilFunctions';
import Categories from '../categories';

const Blog = ({ content, metaStyle, categoryBoxStyle, rootPath, ...restProps }) => {
  const {
    title,
    slug,
    author,
    createdAt,
    publishDate,
    category,
    coverImage,
    content: {
      childContentfulRichText: { html },
    },
  } = content;
  const postDate = publishDate || createdAt;

  return (
    <>
      <BlogWrapper {...restProps}>
        <BlogThumb>
          <Anchor path={`${rootPath}/${slug}`}>
            <Img fluid={coverImage.fluid} alt={title} />
          </Anchor>
        </BlogThumb>
        <BlogInfo>
          <BlogHeader>
            <Categories {...categoryBoxStyle} categories={category} />
            {title && (
              <BlogTitle>
                <Anchor path={`/blog/${slug}`}  key={slug} >{title}</Anchor>
              </BlogTitle>
            )}
            <BlogHeaderMeta>
              {author && <AuthorMeta {...metaStyle} slug={author.slug} name={author.name} />}
              {postDate && <BlogMeta {...metaStyle} text={postDate} icon={<FaCalendarAlt />} />}
            </BlogHeaderMeta>
          </BlogHeader>
          <BlogExcerpt>
            <Truncate
              lines={5}
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </BlogExcerpt>
          <BlogFooter>
            <BlogFooterLeft>
              <Button to={`${rootPath}/${slug}`} hover="2">
                Ler mais
              </Button>
            </BlogFooterLeft>
            {/* <BlogFooterRight>
              <SocialShare title={title} url={`/${slug}`} />
            </BlogFooterRight> */}
          </BlogFooter>
        </BlogInfo>
      </BlogWrapper>
    </>
  );
};

Blog.propTypes = {
  rootPath: PropTypes.string,
  content: PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string }).isRequired,
  metaStyle: PropTypes.shape({
    mt: PropTypes.string,
    mr: PropTypes.string,
  }),
  categoryBoxStyle: PropTypes.shape({
    mb: PropTypes.string,
  }),
};

Blog.defaultProps = {
  rootPath: '/blog',
  metaStyle: {
    mt: '10px',
    mr: '20px',
  },
  categoryBoxStyle: {
    mb: '18px',
  },
};

export default Blog;
