import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Box } from '../../../components/ui/Wrapper';
import Button from '../../../components/ui/button';
import { slugify } from '../../../utils/utilFunctions';

const PopularTags = ({ boxStyle, tagStyle }) => {
  const BlogTagsQuery = useStaticQuery(graphql`
    query BlogSidebarTagsQuery {
      allContentfulCategory {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);
  const tags = BlogTagsQuery.allContentfulCategory.edges;

  return (
    <Box {...boxStyle}>
      {tags.map(({ node: tag }) => {
        return (
          <Button {...tagStyle} to={`/blog/categoria/${tag.slug}`} key={tag.slug}>
            {tag.title}
          </Button>
        );
      })}
    </Box>
  );
};

PopularTags.propTypes = {
  tagStyle: PropTypes.object,
};

PopularTags.defaultProps = {
  boxStyle: {
    m: '-5px',
  },
  tagStyle: {
    hover: '2',
    size: 'xsmall',
    skin: 'light',
    color: '#ababab',
    bgcolor: '#f5f5f5',
    p: '0 14px',
    m: '5px',
    height: '32px',
    lineheight: '32px',
    fontSize: '13px',
  },
};

export default PopularTags;
